
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.resource {
  &-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    @include for-size(phone-portrait-down) {
      flex-direction: column;
    }
    &__element {
      width: 100%;

      &_roles, &_delete {
        margin-left: 20px;
        @include for-size(phone-portrait-down) {
          margin-left: 0;
          margin-top: 8px;
        }
      }
      &_delete {
        width: auto;
        margin-top: 8px;
      }
    }
  }
  &__info {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__name {
    font-size: 14px;
    line-height: 20px;
    color: #101828;
    transition: color 0.3s;
  }
  &__branch {
    display: inline-block;
    margin: 0 0 0 16px;
    color: rgba(16, 24, 40, 0.4);
  }
  &__group-node {
    color: $color-primary-1-day;
  }
  &__branch, &__group-node {
    font-size: 12px;
    line-height: 16px;
  }
}
